import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulPage } from 'hooks/useContentfulPage';

import { SupportedBrokersBlock } from 'components/SupportedBrokersBlock';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { SignupLink } from 'components/Links';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { Cards } from 'components/Cards';
import { PricingBlock } from 'components/PricingBlock';
import { ContentBlock } from 'components/ContentBlock';

import { blogPosts, getEtfCards } from 'page-data/etf-tracker';

import { BlogPreviewCards } from 'components/PreviewCards';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const EtfTracker: FC<LocalizedPageProps> = () => {
  const { sections } = useContentfulPage();

  const {
    translations: { trackedInvestments },
  } = useContentfulLocale();

  const { currentLocale } = useLocalizationContext();
  const isAu = currentLocale.path === 'au';

  const allYourDividendsImg = useGatsbyImage({
    name: 'shared-page-assets/localised/taxable-income-report',
    alt: 'Taxable Income Report',
  });

  const etfCards = getEtfCards({ trackedInvestments });

  return (
    <Layout>
      <Seo />

      <HeroContainer>
        <Section>
          <h1>The X-Ray tool for ETF investors</h1>
          <p>
            See inside your ETFs and avoid overlap in your portfolio with Sharesight's exposure
            report - The ultimate X-ray tool for ETF investors.
          </p>

          <br />
          <br />

          <SignupLink asButton />
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'etf-tracker/hero',
            alt: 'graphic illustration of a pie chart depicting an ETF x-ray tool',
          })}
          boxShadow={false}
        />
      </HeroContainer>
      <Cards cards={etfCards} />
      <Container>
        <Section>
          <h2>See inside your ETFs</h2>
          <p>
            Know what you’re actually invested in and eliminate overlap in your portfolio.
            Sharesight’s <strong>exposure report</strong> shows the combination of shares, bonds and
            securities inside your ETFs.
          </p>
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'etf-tracker/exposure-report',
            alt: "user interface of Sharesight's exposure report",
          })}
          boxShadow={false}
        />
      </Container>

      {!!sections && sections.length > 0 && isAu && (
        <ContentBlock
          header={sections[0]?.header}
          textContent={sections[0]?.textContent}
          image={allYourDividendsImg}
        />
      )}

      <SupportedBrokersBlock background={isAu ? 'white' : 'cream'} />

      <PricingBlock />
      <Container>
        <Section>
          <BlogPreviewCards entries={blogPosts} />
        </Section>
      </Container>
    </Layout>
  );
};

export default EtfTracker;
